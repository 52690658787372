import { useNavigate } from 'react-router-dom';
import User from '../../assets/UserProfile';
import PlayerHeader from '../../components/Common/PlayerHeader';
import { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { json } from 'stream/consumers';
import { Controller, Form, useForm } from 'react-hook-form';
import { useUserData } from '../../Context/UserContext';
import { MdDone, MdEdit, MdInfo } from 'react-icons/md';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import { toast } from 'react-toastify';
import {
    useGetReferalData,
    useUpdateUserAvatar,
    useUpdateUserDetails,
} from '../../apis/hooks/useUser';
import Loader from '../../components/Common/Loader/Loader';
import FilePreview from '../../components/Common/FilePreview/FilePreview';
import { useGetKyc } from '../../apis/hooks/useKYC';
import { GiBattleGear } from 'react-icons/gi';
import { CiDollar } from 'react-icons/ci';
import { FaUsers } from 'react-icons/fa6';
import { FiAlertTriangle } from 'react-icons/fi';
import Logout from '../../Common/Dialog/Logout/Logout';
import { useGetAvatars } from '../../apis/hooks/useAvatar';

const Profile = () => {
    const navigate = useNavigate();
    const { user, fetchUser } = useUserData();
    const { data: avatars } = useGetAvatars();
    const { mutate: uploadFiles, isLoading: fileUploading } = useUploadFiles();
    const { data: kycData, isLoading } = useGetKyc({
        userId: user?.userdetails?.id,
    });

    const { data: referalData } = useGetReferalData({
        userId: user?.userdetails?.id,
    });

    const { mutate: updateUser, isLoading: updatingUser } =
        useUpdateUserDetails();
    const [avatarId, setAvatarId] = useState('');

    const { mutate: updateUserAvatarMutate, isLoading: updatingUserAvatar } =
        useUpdateUserAvatar({
            userId: user?.userdetails?.id,
            avatarId: avatarId,
        });
    const fileInputRef = useRef<any>(null);
    const [showAvatars, setShowAvatars] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [showLogout, setShowLogout] = useState(false);

    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: user?.userdetails?.username,
            mobileNumber: user?.userdetails?.mobileNumber,
            // profileImageId: user?.userdetails?.profileImageId ?? '',
            // emailId: user?.userdetails?.emailId ?? '',
        },
    });

    const onSubmit = (formData: any) => {
        if (formData?.username?.length > 15) {
            toast('More than 15 Characters are not allowed', {
                type: 'error',
            });
            return;
        }
        updateUser(
            {
                id: user?.userdetails?.id,
                username: formData?.username,
                // mobileNumber: +formData?.mobileNumber,
                // profileImageId: formData?.profileImageId,
                // emailId: formData?.emailId,
            },
            {
                onSuccess: (data: any) => {
                    if (data?.data?.success) {
                        toast(data?.data?.message ?? 'Profile updated', {
                            type: 'success',
                        });
                        setIsEdit(true);
                        fetchUser();
                    }
                },
                onError: (error: any) => {
                    toast(
                        Array.isArray(error?.message)
                            ? error?.message[0]
                            : error?.message ?? 'Something went wrong',
                        { type: 'error' }
                    );
                },
            }
        );
    };

    const handleLogout = (value: any) => {
        if (value) {
            localStorage.clear();
            fetchUser();
            navigate('/login');
        } else {
            setShowLogout(false);
        }
    };

    const updateUserAvatar = () => {
        if (avatarId) {
            updateUserAvatarMutate(
                {},
                {
                    onSuccess: (data: any) => {
                        if (data?.status === 200) {
                            toast(data?.data?.message ?? 'Avatar updated', {
                                type: 'success',
                            });
                            setShowAvatars(false);
                            fetchUser();
                        }
                    },
                    onError: (error: any) => {
                        toast(
                            Array.isArray(error?.message)
                                ? error?.message[0]
                                : error?.message ?? 'Something went wrong',
                            { type: 'error' }
                        );
                    },
                }
            );
        }
    };

    return (
        <Layout>
            <div className="mt-[20px] flex flex-col justify-center items-center gap-4">
                {/* Profile */}
                <div className="border rounded-xl w-[90%] shadow-[0_8px_30px_rgb(0,0,0,0.12)] border-[#0000002d]">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b border-[#0000002d]">
                        Profile
                    </span>
                    <div className="flex flex-col gap-2 p-2">
                        <div className="flex">
                            {/* {user?.userdetails?.profileImageData?.id ? ( */}
                            <div className="flex flex-col w-full">
                                {showAvatars ? (
                                    <div className="border rounded-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] mx-auto w-full">
                                        <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b">
                                            Select Avatar
                                        </span>
                                        <div className="flex gap-2 p-2">
                                            {avatars?.map(
                                                (data: any, index: number) => {
                                                    return (
                                                        <div
                                                            className={`${avatarId === data?.id ? 'border-2 border-solid border-blue-600 rounded-full' : ''}`}
                                                        >
                                                            <img
                                                                className="cursor-pointer"
                                                                src={
                                                                    data?.file
                                                                        ?.fullUrl
                                                                }
                                                                alt="avatar"
                                                                width={80}
                                                                height={80}
                                                                onClick={() =>
                                                                    setAvatarId(
                                                                        data?.id
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div className="p-4">
                                            <button
                                                className="border w-full border-red-700 text-[red] hover:text-white rounded-lg hover:bg-red-700 p-2 text-lg"
                                                onClick={() =>
                                                    updateUserAvatar()
                                                }
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="relative flex items-center justify-center gap-2">
                                        {user?.userdetails?.avatar?.id ? (
                                            <img
                                                src={
                                                    user?.userdetails?.avatar
                                                        ?.file?.fullUrl
                                                }
                                                alt="profile"
                                                className="w-[60px] h-[60px] rounded-full"
                                            />
                                        ) : (
                                            <div className="w-[60px] h-[60px] rounded-full bg-gray-300"></div>
                                        )}
                                        <button
                                            className={`rounded-full p-1 cursor-pointer border absolute left-[55%] bg-white bottom-0 hover:bg-gray-300`}
                                            onClick={() => setShowAvatars(true)}
                                        >
                                            <MdEdit size={16} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col">
                                    <p>Username</p>
                                    <div className="flex items-center justify-between gap-2">
                                        <Controller
                                            name="username"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({
                                                field: {
                                                    ref,
                                                    ...hookFormField
                                                },
                                                fieldState,
                                            }) => (
                                                <input
                                                    type="text"
                                                    className={`border rounded p-2 w-[150px] sm:w-[200px] flex-1 ${fieldState.error ? 'border-red-600' : ''} ${isEdit ? 'bg-[#e9ecef]' : ''}`}
                                                    {...hookFormField}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                        <button
                                            type="button"
                                            className={`${isEdit ? 'bg-[#0d6efd] hover:bg-[#2f60a8]' : 'bg-[#198754] hover:bg-[#22774f]'} text-white rounded-md p-2 w-[100px]`}
                                            onClick={() => {
                                                if (isEdit) {
                                                    setIsEdit(false); // Switch to "Save" mode (editing mode)
                                                } else {
                                                    handleSubmit(onSubmit)(); // Trigger form submission
                                                }
                                            }}
                                        >
                                            {isEdit ? 'Edit' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-col ">
                                    <span className="">Phone</span>
                                    <Controller
                                        name="mobileNumber"
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message:
                                                    'Please enter a valid phone number',
                                            },
                                            minLength: {
                                                value: 10,
                                                message:
                                                    'Please enter a minimun 10 digit number',
                                            },
                                            maxLength: {
                                                value: 10,
                                                message:
                                                    'Please enter a maximun 10 digit number',
                                            },
                                        }}
                                        control={control}
                                        disabled
                                        render={({
                                            field: {
                                                ref,
                                                value,
                                                ...hookFormField
                                            },
                                            fieldState,
                                        }) => (
                                            <div>
                                                <input
                                                    type="text"
                                                    inputMode="numeric"
                                                    value={
                                                        user?.userdetails
                                                            ?.mobileNumber
                                                    }
                                                    className={`p-2 border rounded focus:outline-none w-full bg-[#e9ecef] ${fieldState.error ? 'border-red-600' : ''}`}
                                                    {...hookFormField}
                                                />
                                                {!!fieldState.error && (
                                                    <div className="text-xs text-red-600">
                                                        {
                                                            fieldState.error
                                                                .message
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="flex justify-between p-2 m-2 border border-[#212529] rounded-md">
                        <div className="flex flex-col">
                            <p className="text-xs">KYC Status</p>
                            <p className="font-semibold">
                                {kycData?.status ? (
                                    <>
                                        <span className="font-semibold">
                                            {kycData?.status}
                                        </span>
                                        {/* {kycData?.status === 'Approved' ? (
                                            <MdDone color="green" size={24} />
                                        ) : (
                                            <MdInfo color="red" size={24} />
                                        )} */}
                                    </>
                                ) : (
                                    <span className="text-green-700">
                                        Pending
                                    </span>
                                )}
                            </p>
                        </div>
                        <div className="font-medium">
                            {kycData?.status !== 'Approved' ? (
                                <button
                                    className="p-2 text-[#198754] border border-[#198754] hover:bg-[#198754] hover:text-white rounded-md"
                                    onClick={() => navigate('/kyc')}
                                >
                                    Complete KYC
                                </button>
                            ) : (
                                <button
                                    className="p-2 text-[#198754] border border-[#198754] hover:bg-[#198754] hover:text-white rounded-md"
                                    onClick={() => navigate('/kyc')}
                                >
                                    View Kyc Detail
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                {/* Metrics */}
                <div className="border rounded-xl w-[90%] shadow-[0_8px_30px_rgb(0,0,0,0.12)] border-[#0000002d]">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4 text-center border-b border-[#0000002d]">
                        Metrics
                    </span>
                    <div className="grid grid-cols-2 gap-4 p-4 text-sm">
                        <div className="border rounded-lg">
                            <div className="flex gap-2 bg-[#F8F9FA] items-center border-b p-2 border-[#0000002d] ">
                                <GiBattleGear />
                                <p>Games Played</p>
                            </div>
                            <div className="p-2 text-lg font-semibold">
                                {user?.userdetails?.totalGamesPlayed ?? 0}
                            </div>
                        </div>

                        <div className="border rounded-lg">
                            <div className="flex gap-2 bg-[#F8F9FA] items-center border-b p-2 border-[#0000002d] ">
                                <CiDollar />
                                <p>Chips Won</p>
                            </div>
                            <div className="p-2 text-lg font-semibold">
                                {user?.userdetails?.totalWinning ?? 0}
                            </div>
                        </div>

                        <div className="border rounded-lg">
                            <div className="flex gap-2 bg-[#F8F9FA] items-center border-b p-2 border-[#0000002d] ">
                                <FaUsers />
                                <p>Referal</p>
                            </div>
                            <div className="p-2 text-lg font-semibold">
                                {user?.userdetails?.totalReferalEarning ?? 0}
                            </div>
                        </div>

                        <div className="border rounded-lg">
                            <div className="flex gap-2 bg-[#F8F9FA] items-center border-b p-2 border-[#0000002d]">
                                <FiAlertTriangle />
                                <p>Penalty</p>
                            </div>
                            <div className="p-2 text-lg font-semibold">
                                {user?.userdetails?.totalPenalty ?? 0}
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    className="w-[90%] border border-red-700 text-[red] hover:text-white rounded-lg mt-4 hover:bg-red-700 p-2 text-lg"
                    onClick={() => setShowLogout(true)}
                >
                    Logout
                </button>
            </div>

            <Logout isOpen={showLogout} onClose={handleLogout} />
        </Layout>
    );
};

export default Profile;
