import React from 'react';
import WhatsappIcon from '../../../assets/WhatsappIcon';

const WhatsappSupport = () => {
    const openWhatsApp = () => {
        window.open('https://wa.me/9350565551', '_blank'); // Replace with your WhatsApp number
    };
    return (
        <div
            onClick={openWhatsApp}
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                backgroundColor: '#000',
                color: 'white',
                borderRadius: '25%',
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 10,
            }}
        >
            <WhatsappIcon className="w-8 h-8" />
        </div>
    );
};

export default WhatsappSupport;
