import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Common/Layout/Layout';
import upiImage from '../../assets/upi.png';
import { usePaymentMethod } from '../../apis/hooks/usePaymentMethod';
const PrePayment = () => {
    const navigate = useNavigate();
    const { data: paymentMethods, isLoading } = usePaymentMethod();
    const amount = JSON.parse(localStorage.getItem('addPayment') || '');
    console.log('paymentMethods', paymentMethods);

    return (
        <Layout>
            <section className="w-full mt-2">
                <div className="w-[90%] mx-auto">
                    <span className="text-[#0d6efd] text-[12px] font-bold pb-2 border-b-[5px] border-[#F8F8F8] flex items-center justify-center w-full text-center">
                        Pay Through UPI ID
                    </span>
                </div>

                <div className="w-[90%] mx-auto p-2 mt-2 mb-8 flex gap-2 justify-between font-semibold items-center">
                    <h1>Amount to be added ₹ {amount ?? 0}</h1>
                    <button
                        className="px-2 py-1 text-white bg-[#0d6efd] rounded"
                        onClick={() => navigate('/wallet/addWallet')}
                    >
                        Edit
                    </button>
                </div>

                <div className="w-[90%] mx-auto flex flex-col gap-2">
                    {amount > 2000 ? (
                        <button
                            className={`rounded-lg w-full px-2 flex gap-2 font-semibold border border-black items-center`}
                            onClick={() => {
                                navigate('/wallet/addWallet/qr-payment');
                            }}
                        >
                            <img src={upiImage} className="w-10" alt="qr" />
                            <p>QR CODE</p>
                        </button>
                    ) : (
                        <button
                            className={`rounded-lg w-full px-2 flex gap-2 font-semibold border border-black items-center`}
                            onClick={() => {
                                navigate('/wallet/addWallet/upi-payment');
                            }}
                        >
                            <img src={upiImage} className="w-10" alt="qr" />
                            <p>PAY UPI</p>
                        </button>
                        // paymentMethods
                        //     ?.slice()
                        //     .reverse()
                        //     ?.map((data: any) => {
                        //         return (
                        //             data?.status && (
                        //                 <button
                        //                     key={data?.id}
                        //                     className={`rounded-lg w-full px-2 flex gap-2 font-semibold border border-black items-center`}
                        //                     onClick={() => {
                        //                         if (
                        //                             data?.paymentMethod ===
                        //                             'upi_gateway'
                        //                         ) {
                        //                             navigate(
                        //                                 '/wallet/addWallet/upi-payment'
                        //                             );
                        //                         } else {
                        //                             navigate(
                        //                                 '/wallet/addWallet/qr-payment'
                        //                             );
                        //                         }
                        //                     }}
                        //                 >
                        //                     {data?.paymentMethod ===
                        //                     'upi_gateway' ? (
                        //                         <img
                        //                             src={upiImage}
                        //                             className="w-10"
                        //                             alt="upi"
                        //                         />
                        //                     ) : (
                        //                         <img
                        //                             src={upiImage}
                        //                             className="w-10"
                        //                             alt="qr"
                        //                         />
                        //                     )}

                        //                     {data?.paymentMethod ===
                        //                     'upi_gateway' ? (
                        //                         <p>PAY UPI</p>
                        //                     ) : (
                        //                         <p>QR CODE</p>
                        //                     )}
                        //                 </button>
                        //             )
                        //         );
                        //     })
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default PrePayment;
